.educationList {
	padding: 30px 15px;
}

.edu-img-cont {
	display: flex;
	justify-content: center;
}

.edu-img {
	width: 100%;
	align-self: center;
}

.edu-year {
	font-size: 1rem;
}

.edu-uni {
	font-size: 1.75rem;
}

.edu-degree {
	font-size: 1.25rem;
}

.edu-gpa {
	font-size: 1rem;
	color: #495057;
}

.experienceList {
	padding: 30px 15px;
	text-align: center;
}

.exp-img {
	height: 100px;
	max-height: 100px;
	max-width: 100vw;
	padding-bottom: 10px;
}

.exp-year {
	font-size: 1rem;
}

.exp-comp {
	font-size: 1.75rem;
}

.exp-pos {
	font-size: 1.25rem;
}

.exp-stat {
	font-size: 1rem;
	color: #495057;
}

.projectsList {
	padding: 30px 15px;
	text-align: center;
}

.proj-year {
	font-size: 1rem;
}

.proj-name {
	font-size: 1.75rem;
}

.proj-tools {
	font-size: 1.25rem;
}

.proj-link {
	font-size: 1rem;
}

.proj-link a {
	color: #495057;
	text-decoration: none;
}
.proj-link a:hover {
	color: #495057;
	text-decoration: underline;
}

.skillsList {
	padding: 30px 15px;
	text-align: center;
}

.skill-name {
	font-size: 1.75rem;
}

.skill-tools {
	font-size: 1.25rem;
}
